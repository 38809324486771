import { connectWallet } from './w3.js';

const dev = 'http://localhost:4000/';
const prod = 'https://musicgm.xyz/';
const envUrl = prod;
const defaultAddress = "0xb93e01b23b88be096fba513ee125124125f21f79";

export async function initializeWallet() {
  var address = await connectWallet();
  console.log(`connected: ${address}`);
  if (address == '0x58fCB779d87Bb53d1A4630472f803BbAB329667e') {
    // test address
    address = 'sapox.eth';
  }
  if (address) {
    fetchAddressRecommendations(address);
  }
  // Other code that relies on `wallets`
}

function updateGrid(data) {
  showLoader(false);
// Get the grid container element by ID
  const gridContainer = document.getElementById("results-grid");
  // remove old results
  while (gridContainer.firstChild) {
    gridContainer.removeChild(gridContainer.firstChild);
  }
  // Get the grid item template
  const gridItemTemplate = document.querySelector(".grid-item-template");
  const songEmbed = document.getElementById("grid-player");
  // Loop through the data and create grid items
  data.forEach((item) => {
    // Clone the grid item template
    const gridItem = gridItemTemplate.cloneNode(true);
    const songCover = gridItem.querySelector(".song-cover");
    songCover.classList.add("flip-cover");
    const songDescription = gridItem.querySelector(".desc-block");
    songDescription.classList.add("flip-description");
    // Remove the template class and set display to initial
    gridItem.classList.remove("grid-item-template");
    gridItem.style.display = "";
    // Populate the grid item with data
    gridItem.querySelector(".artist-name").textContent = item.artist;
    gridItem.querySelector(".song-title").textContent = item.title;
    gridItem.querySelector(".artist-image").src = item.artistAvatarUrl;
    gridItem.querySelector(".div-artist").addEventListener("click", function () {
      let url = "/artist.html?artist=" + item.artistId;
      window.open(url, '_blank');
    });
    songCover.loading = 'lazy';
    songCover.src = item.coverImage;
    songCover.onload = () => {
      songCover.style.display = 'block'; // Show the image when it's loaded
    };
    gridItem.querySelector(".song-description").textContent = item.description;

    gridItem.querySelector(".song-collect-button").addEventListener("click", function () {
        let collectUrl = item.webappUri;
        if (typeof collectUrl !== 'undefined' && collectUrl.length > 0) {
          let url = collectUrl + "?referral=0xfdd6af85172a18a02651e63929ff2f46e4714156&referral_source=link";
          window.open(url, '_blank');
        }
    });
    gridItem.addEventListener("click", function () {
      if (!event.target.closest(".song-collect-button")) {
        songCover.classList.toggle("flipped-image");
       songDescription.classList.toggle("flipped-desc");		    
      }
    });
    // Add a mouseover event listener (hover)
    //gridItem.addEventListener("mouseover", function () {
    // Append the grid item to the grid container
    // Append the grid item to the grid container
    gridItem.style.maxWidth = "280px";
    gridContainer.appendChild(gridItem);
  });
}

function popupAlert() {
  const alertElement = document.getElementById('timed-alert');
  setTimeout(() => {
    alertElement.style.display = 'block';
  }, 1000);
  setTimeout(() => {
      alertElement.style.display = 'none';
  }, 10000);
  const close = document.getElementById('timed-alert-close-action');
  close.addEventListener("click", () => {
    alertElement.style.display = 'none';
  });
}

function loadSpotifyStatus() {
  showLoader(true);
  spotifyLogin();
}

var state = '';
async function spotifyLogin() {
  popupAlert();
  try {
  const response = await fetch(`${envUrl}spotify-login`);
  if (response.status === 200) { 
    const url = await response.text();
    // Extract the state from the URL
    let urlObj = new URL(url);
    state = urlObj.searchParams.get('state');
    window.open(url, '_blank');
    pollSpotifyState(state);
  } else if  (response.status === 201) {
    fetchW2Recommendations();
  } else {
    document.getElementById('popup').style.display = "block";
    showLoader(false);
  }
  
  } catch (error) {
    showLoader(false);
      console.error('Error:', error);
  }
};

var spotifyRetries = 0;
const maxRetries = 20;
async function pollSpotifyState(state) {
  let res = await fetch(`${envUrl}spotify-auth-status/${state}`);
  if (res.status === 200) {
    const spotButton = document.getElementById("spotify-connect");
    spotButton.textContent = "Connected";
    fetchW2Recommendations();
  } else if (res.status === 202) {
    if (spotifyRetries < maxRetries) {
      spotifyRetries++;
      console.log(`no token, will retry ${spotifyRetries}`);
      setTimeout(() => pollSpotifyState(state), 3000);
    } else {
      console.log("max retries for spotify");
      showLoader(false);
    }
  } else {
      console.log("Failed to get tokens");
      showLoader(false);
  }
}

function fetchW2Recommendations() {
  showLoader(true);
  const alertElement = document.getElementById('timed-alert');
  alertElement.style.display = 'none';
$.ajax({
    url: `${envUrl}api/spotify-artists`,
    method: "GET",
    data: { state: state },
    contentType: "application/json", // Set content type to JSON
    success: function(data) {
      console.log(`got recommendations: ${data.length}`);
      updateGrid(data);
    },
    error: function(xhr, status, error) {
      console.error(error);
      showLoader(false);
      document.getElementById('popup').style.display = "block";
    }
  });
};

function fetchPassRecommendations(passId) {
  showLoader(true);
  $.ajax({
      url: `${envUrl}api/pass-recommendations`,
      method: "GET",
      data: { passId: passId },
      contentType: "application/json", // Set content type to JSON
      success: function(data) {
        console.log(`got recommendations: ${data.feed.length}`);
        if (data.feed.length > 0) {
          updateGrid(data.feed);
        }
        showLoader(false);
      },
      error: function(xhr, status, error) {
        showLoader(false);
        console.error(error);
      }
    });
}

function fetchAddressRecommendations(address) {
  showLoader(true);
  $.ajax({
      url: `${envUrl}api/address-recommendations`,
      method: "GET",
      data: { address: address },
      contentType: "application/json", // Set content type to JSON
      success: function(data) {
        console.log(`got recommendations: ${data.feed.length}`);
        if (data.feed.length > 0) {
          updateGrid(data.feed);
        }
        showLoader(false);
      },
      error: function(xhr, status, error) {
        showLoader(false);
        console.error(error);
      }
    });
}

function showLoader(show) {
  const loader = document.getElementById("loader");
   if (show) {
     loader.style.display = "block";
   } else {
    loader.style.display = "none";
   }
}

function isValidEthereumAddress(address) {
  const regex = /^(0x)?[0-9a-fA-F]{40}$/;
  return regex.test(address);
}

document.addEventListener("DOMContentLoaded", function () {
  const button = document.getElementById("spotify-connect");
  button.addEventListener("click", loadSpotifyStatus);
  const btnProfile = document.getElementById("profile-connect");
  btnProfile.addEventListener("click", () => {
//    fetchAddressRecommendations("sapox.eth"); 
    initializeWallet();
  });
  const popupclose = document.getElementById("close-action");
  popupclose.addEventListener("click", () => {
    document.getElementById('popup').style.display = "none";
  });

  const popupaction = document.getElementById("popup-action");
    popupaction.addEventListener("click", () => {
    document.getElementById('popup').style.display = "none";
    window.open('https://twitter.com/musictribes_xyz', '_blank');
  });

  const queryParams = new URLSearchParams(window.location.search);
  const passId = queryParams.get('pass');
  const address = queryParams.get('add');
  if (passId) {
    console.log(`url passId: ${passId}`);
    fetchPassRecommendations(passId);
  } else if (address) {
    fetchAddressRecommendations(address);
  } else {
    console.log('loading default feed');
    fetchAddressRecommendations(defaultAddress);  
  }

  // Get a reference to the text field element
  const textField = document.getElementById('text-home-addres-input');
  // Add an event listener to the text field to listen for input changes
  textField.addEventListener('input', function(event) {
    // Access the value of the text field using event.target.value
    const inputValue = event.target.value;
    if (inputValue === null || inputValue === undefined) {
      console.log('Input Value is null or undefined');
      return;
    }
  
    if (inputValue.endsWith('.eth')) {
      console.log('entered ens address');
      fetchAddressRecommendations(inputValue);
    } else if (isValidEthereumAddress(inputValue)) {
      console.log('entered valid Ethereum address');
      fetchAddressRecommendations(inputValue);
    }
  });
});

import bannerImageUrl from '../images/banner-web.png';

window.addEventListener('DOMContentLoaded', (event) => {
  document.querySelector('meta[property="og:image"]').setAttribute('content', bannerImageUrl);
  document.querySelector('meta[name="twitter:image"]').setAttribute('content', bannerImageUrl);
});